document.addEventListener('DOMContentLoaded', () => {
    const slides = [
        '.slider__item--pharmacies',
        '.slider__item--providers',
        '.slider__item--patients',
    ];

    const slidesString = slides.join();

    $(slidesString).mouseenter(function () {
        $(this).addClass('active').siblings().addClass('inactive');
    });

    $(slidesString).mouseleave(function () {
        $(slidesString).removeClass('active inactive');
    });

    // Fix for when the slider item content is stuck after window.history.back() from another page
    $('body').mouseenter(function () {
        $(slidesString).removeClass('active inactive');
    });
});

